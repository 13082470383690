import * as React from "react"
import Layout from "../components/layout"

const ContactPage = () => (
  <Layout title={"NFTs"}>
    <h1 className="text-center p-5 mt-5 font-play">NFT's coming soon...</h1>
  </Layout>
)

export default ContactPage
